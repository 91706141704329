import { HeaderForm } from '@core/components/Form';
import { AlphaBox } from '@core/components/Layout';
import { BreadcrumbForm } from '@core/components/LayoutTheme';
import HistoryTable from '@core/components/Table/HistoryTable';
import { Box, Button, Card, CircularProgress, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

const unitSchema = z.object({
  name: z.number({ required_error: 'Nome é obrigatório' }),
});

const historyColumns = [
  { id: 'data', label: 'Data' },
  { id: 'user', label: 'Usuário' },
];
const historyRows = [
  { data: '01/08/2024', user: 'João Santos' },
  { data: '23/07/2024', user: 'Igor Prado' },
  { data: '13/11/2024', user: 'Tiago Lang' },
  { data: '17/12/2024', user: 'Bruno Assunção' },
];

const UnitFormPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const isEditing = !!id;

  const mutationCreate = useMutation({
    //mutationFn: registerGroup(values),
    onSuccess: () => {
      queryClient.invalidateQueries();
      navigate(-1);
    },
    onError: (error: unknown) => {
      console.error('Error create group:', error);
    },
  });
  const mutationUpdate = useMutation({
    /* mutationFn: ({ id, values }: { id: string; values: any }) =>
      editGroup(id, values), */
    onSuccess: () => {
      queryClient.invalidateQueries();
      navigate(-1);
    },
    onError: (error: unknown) => {
      console.error('Error updating group:', error);
    },
  });

  const { data: unit } = useQuery<any>({
    queryKey: ['fetchGroup', id],
    queryFn: () => {
      /*  if (id !== undefined) {
        return fetchGroup(id);
      } */
      return Promise.reject('ID is undefined');
    },
    enabled: isEditing && id !== undefined,
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: toFormikValidationSchema(unitSchema),
    onSubmit: async (values) => {
      if (isEditing && id) {
        //mutationUpdate.mutate({ id, values });
      } else {
        //mutationCreate.mutate({ values });
      }
    },
  });

  useEffect(() => {
    if (unit) {
      formik.setValues({
        name: unit.name || 0,
      });
    }
  }, [unit]);

  return (
    <AlphaBox>
      <BreadcrumbForm
        routeSegments={[
          {
            name: 'Gerenciar Unidades',
            path: '/tools/manageUnit',
          },
        ]}
      />
      <Grid container spacing={3} alignItems="center" p={6}>
        <Grid size={{ xs: 12, md: 12 }}>
          <Box component="form" onSubmit={formik.handleSubmit}>
            {isEditing ? (
              <HeaderForm
                //title={formik.values?.name}
                title="Editar Unidade"
                subtitle="Editar Unidade"
              />
            ) : (
              <HeaderForm title="Unidade" subtitle="Cadastrar" />
            )}
            <Card elevation={2} sx={{ borderRadius: '10px', padding: '5px' }}>
              <Grid container spacing={2} p={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Nome"
                    disabled={isEditing}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
              </Grid>
              {isEditing ? (
                <Grid container spacing={2} p={2}>
                  <HistoryTable
                    columns={historyColumns}
                    rows={historyRows}
                    headerColor="info"
                  />
                </Grid>
              ) : null}
              <Grid size={{ xs: 12, md: 12 }} p={2} textAlign="right">
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={() => navigate(-1)}
                  sx={{ mr: 2 }}
                >
                  Voltar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    mutationCreate.isPending || mutationUpdate.isPending
                  }
                >
                  {mutationCreate.isPending || mutationUpdate.isPending ? (
                    <CircularProgress size={24} />
                  ) : isEditing ? (
                    'Salvar Alterações'
                  ) : (
                    'Cadastrar'
                  )}
                </Button>
              </Grid>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </AlphaBox>
  );
};

export default UnitFormPage;

import React, { useCallback, useEffect } from 'react';
import { Box, Button, Divider, LinearProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CreateList from '@core/components/DataTable/CreateList';
import { NavLink, useNavigate } from 'react-router-dom';
import { AlphaBox } from '@core/components/Layout';
import { Breadcrumb } from '@core/components/LayoutTheme';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import useTable from '@core/hooks/useTable';
import useComponent from '@core/hooks/useComponent';

const UnitListPage: React.FC = () => {
  const navigate = useNavigate();
  const { columns, actions, updateColumns, updateActions, isLoadingStructure } =
    useTable({
      endpoint: 'UnitList',
    });

  const updateTableColumns = useCallback(() => {
    updateColumns([{ column: 'id', header: '#', size: 100, hideFilter: true }]);
  }, []);

  const updateTableActions = useCallback(() => {
    updateActions({});
  }, [navigate]);

  useEffect(() => {
    if (!isLoadingStructure) {
      updateTableColumns();
      updateTableActions();
    }
  }, [isLoadingStructure, updateColumns, updateActions]);

  const componentAddUnit = useComponent('/tools/manageUnit/add');

  return (
    <AlphaBox>
      <Breadcrumb
        routeSegments={[
          { name: 'Ferramentas' },
          { name: 'Gerenciar Unidades' },
        ]}
      />
      <Grid container spacing={3} alignItems="center" mb={2} mt={1}>
        <Grid size={{ xs: 12, sm: 12 }} style={{ textAlign: 'right' }}>
          {componentAddUnit.visible && (
            <NavLink to="/tools/manageUnit/add">
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddRoundedIcon />}
              >
                {componentAddUnit.name}
              </Button>
            </NavLink>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Box mt={3}>
        {isLoadingStructure ? (
          <LinearProgress />
        ) : (
          <CreateList
            tableEndpoint="UnitList"
            menuItems={actions}
            columns={columns}
            exportOptions={{ fileName: 'Unidades' }}
            propsQuery={{
              staleTime: 1000 * 60 * 3, // 3 minutes
            }}
          />
        )}
      </Box>
    </AlphaBox>
  );
};

export default UnitListPage;

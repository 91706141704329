import { request } from '@core/data/request';

export interface RegisterCompanyParams {
  name: string;
  address: string;
  address_complement: string;
  number: string;
  telephone: string;
  state_id: number;
  city_id: number;
  neighborhood: string;
  matrix_id: number;
  nickname: string;
  logo?: File | null | undefined;
  pmc: boolean | number;
  cep: string;
  city?: CityParams;
}

export interface CompanyListParams {
  id: number;
  name: string;
  nickname: string;
  logo: any;
  pmc: number;
  address: string;
  address_complement: string;
  neighborhood: string;
  cep: string;
  telephone: string;
  city: string;
  matrix: string;
}

export interface MatrixParams {
  id: number;
  name: string;
}

export interface StateParams {
  id: number;
  name: string;
  uf: string;
}

export interface CityParams {
  id: number;
  state_id: number;
  name: string;
  state?: StateParams;
}

export interface CepFindParams {
  cep: string;
  city_id?: number;
  state_id?: number;
  state?: {
    id: number;
    name: string;
    uf: string;
  };
  city?: {
    id: number;
    state_id: number;
    name: string;
  };
}

export const registerCompany = async (companyData: RegisterCompanyParams) => {
  return request<RegisterCompanyParams>({
    method: 'post',
    url: '/company/register-company',
    data: {
      ...companyData,
    },
  });
};

export const fetchCompany = async (
  id: number | string,
): Promise<RegisterCompanyParams> => {
  return request<RegisterCompanyParams>({
    method: 'get',
    url: `/company/edit-company/${id}`,
  });
};

export const editCompany = async (
  id: number | string,
  companyData: RegisterCompanyParams,
) => {
  return request<RegisterCompanyParams>({
    method: 'put',
    url: `/company/edit-company/${id}`,
    data: {
      ...companyData,
    },
  });
};

export const fetchAllMatrices = async (): Promise<MatrixParams[]> => {
  return request<MatrixParams[]>({
    method: 'get',
    url: '/matrix/list-all-matrices',
  });
};

export const fetchFindCep = async (
  cep: string,
): Promise<CepFindParams | null> => {
  return request<CepFindParams>({
    method: 'get',
    url: `/location/cep-find`,
    data: {
      cep,
    },
  });
};

import { useCallback, useEffect, useRef } from 'react';

const useShortcut = (
  keys: string[],
  callback: (event: KeyboardEvent) => void,
) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const allKeysPressed = keys.every((key) =>
        key === 'ctrl'
          ? event.ctrlKey
          : event.key.toLowerCase() === key.toLowerCase(),
      );
      if (allKeysPressed) {
        event.preventDefault();
        callbackRef.current(event);
      }
    },
    [keys],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
};

export default useShortcut;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';

interface DatePickerFilterProps {
  handleCustomFilterChange: (header: any, value: any) => void;
  getCustomFilterValue: () => any;
  theme: any;
  header: any;
  isCustom: boolean;
  headerFilter: boolean;
  handleFilterChange: (value: any, isCustom: boolean) => void;
}

interface DateRange {
  from: Dayjs | null;
  to: Dayjs | null;
}

const DatePickerFilter: React.FC<DatePickerFilterProps> = React.memo(
  ({
    handleCustomFilterChange,
    getCustomFilterValue,
    theme,
    header,
    headerFilter,
  }) => {
    const [localDates, setLocalDates] = useState<DateRange>({
      from: null,
      to: null,
    });

    useEffect(() => {
      const currentValue = getCustomFilterValue();
      if (!currentValue) {
        setLocalDates({ from: null, to: null });
      }
    }, [getCustomFilterValue]);

    const handleDateChange = useCallback(
      (newValue: Dayjs | null, type: 'from' | 'to') => {
        const newDates = {
          ...localDates,
          [type]: newValue,
        };

        setLocalDates(newDates);

        if (newDates.from || newDates.to) {
          const filterValue = {
            from: newDates.from ? newDates.from.format('YYYY-MM-DD') : null,
            to: newDates.to ? newDates.to.format('YYYY-MM-DD') : null,
          };
          handleCustomFilterChange(header, filterValue);
        } else {
          handleCustomFilterChange(header, '');
        }
      },
      [localDates, handleCustomFilterChange, header],
    );

    const textFieldSx = useMemo(
      () => ({
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#d3d3d3' : '#737373',
        },
        ...(headerFilter
          ? {
              width: '100%',
              maxWidth: '100%',
              ...header.column.columnDef.filterStyle,
            }
          : {}),
      }),
      [theme.palette.mode, headerFilter, header.column.columnDef.filterStyle],
    );

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Stack direction="row" spacing={1}>
          <Tooltip
            title={`Data Início ${header.column.columnDef.header}`}
            placement="top"
          >
            <Box>
              <DatePicker
                label={`Data Início ${header.column.columnDef.header}`}
                value={localDates.from}
                onChange={(newValue) => handleDateChange(newValue, 'from')}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    sx: textFieldSx,
                  },
                }}
              />
            </Box>
          </Tooltip>
          <Tooltip
            title={`Data Final ${header.column.columnDef.header}`}
            placement="top"
          >
            <Box>
              <DatePicker
                label={`Data Final ${header.column.columnDef.header}`}
                value={localDates.to}
                onChange={(newValue) => handleDateChange(newValue, 'to')}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    sx: textFieldSx,
                  },
                }}
              />
            </Box>
          </Tooltip>
        </Stack>
      </LocalizationProvider>
    );
  },
);

DatePickerFilter.displayName = 'DatePickerFilter';

export default DatePickerFilter;

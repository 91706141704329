import { AlphaBox } from '@core/components/Layout';
import { motion } from 'framer-motion';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  changePasswordUser,
  UserChangePasswordParams,
} from '../services/userProfileService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@ilogix/hooks/useAuth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useAlphaModal } from '@core/components/Modal/AlphaModal';
import { notify } from '@core/utils/notifyUtils';

const conditions = [
  { regex: /.{8,}/, label: '8-20 caracteres' },
  { regex: /[A-Z]/, label: '1 letra maiúscula' },
  { regex: /\d/, label: '1 número' },
  { regex: /[@$!%*?&#]/, label: '1 caractere especial' },
];

const ProfileSecurity = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { handleLogout } = useAuth();
  const [showConditions, setShowConditions] = useState(false);
  const AlphaModal = useAlphaModal();

  const validatePassword = (password: string) => {
    return conditions.map((condition) => condition.regex.test(password));
  };

  const mutationUpdate = useMutation({
    mutationFn: ({ values }: { values: UserChangePasswordParams }) =>
      changePasswordUser(values),
    onSuccess: () => {
      notify(
        'success',
        'Salvo com sucesso. É necessário logar novamente com sua nova senha',
      );
    },
    onError: (error: unknown) => {
      console.error('Error updating user:', error);
    },
  });

  const handleUpdate = (values: UserChangePasswordParams) => {
    mutationUpdate.mutate({ values });
    handleLogout();
    navigate('/session/signin');
  };

  const handleSavePassword = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      handleUpdate({ password, newPassword });
    }, 1000);
  };

  const validationStatus = validatePassword(newPassword);

  return (
    <AlphaBox>
      <Typography variant={isMobile ? 'h5' : 'h4'} mb={1}>
        Segurança
      </Typography>
      <Typography color="gray" sx={{ mb: 5 }}>
        Defina as configurações de segurança
      </Typography>
      <Divider />
      <Stack
        sx={{ mt: 5, mb: 5 }}
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
      >
        <Box>
          <Typography variant="h5">Senha</Typography>
          <Typography color="gray" fontSize={13}>
            Altere sua senha
          </Typography>
        </Box>
        <Stack
          sx={{ pl: isMobile ? 0 : 20 }}
          width={isMobile ? '100%' : '40%'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          {/* <TextField
            size="medium"
            fullWidth
            disabled
            type="password"
            variant="outlined"
            placeholder="Senha"
            value={'senhasenha'}
            slotProps={{
              input: {
                endAdornment: (
                  <Tooltip title="Clique no botão ao lado para alterar sua senha">
                    <LiveHelpRoundedIcon sx={{ color: 'gray' }} />
                  </Tooltip>
                ),
              },
            }}
          /> */}
          <Button
            sx={{ width: '50%', ml: 2 }}
            variant="contained"
            onClick={handleOpen}
          >
            Alterar Senha
          </Button>
        </Stack>
      </Stack>
      <AlphaModal.Root open={open} onClose={handleClose} title="Alterar Senha">
        <AlphaModal.Content>
          <Box p={2}>
            <Stack
              sx={{ mt: 3, mb: 2 }}
              alignItems={'center'}
              direction={'row'}
              spacing={2}
            >
              <Typography fontSize={15} sx={{ width: '40%' }}>
                Senha Atual:
              </Typography>
              <TextField
                size="small"
                fullWidth
                type={showPassword ? 'password' : 'text'}
                variant="outlined"
                placeholder="Senha Atual"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Stack>
            <Stack
              sx={{ mt: 2, mb: 3 }}
              alignItems={'center'}
              direction={'row'}
              spacing={2}
            >
              <Typography fontSize={15} sx={{ width: '40%' }}>
                Nova Senha:
              </Typography>
              <TextField
                size="small"
                fullWidth
                type={showNewPassword ? 'password' : 'text'}
                variant="outlined"
                placeholder="Nova Senha"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                autoComplete="new-password"
                onFocus={() => setShowConditions(true)}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Stack>
            {showConditions && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Card elevation={3}>
                  <CardContent>
                    <Grid container spacing={2}>
                      {conditions.map((condition, index) => (
                        <Grid size={{ xs: 12, md: 12 }} key={index}>
                          <Box display="flex" alignItems="center">
                            {validationStatus[index] ? (
                              <CheckIcon
                                color="success"
                                style={{ marginRight: 8 }}
                              />
                            ) : (
                              <CloseIcon
                                color="error"
                                style={{ marginRight: 8 }}
                              />
                            )}
                            <Typography>{condition.label}</Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </motion.div>
            )}
          </Box>
        </AlphaModal.Content>
        <AlphaModal.Footer>
          <AlphaModal.Action onClick={handleClose} color="error" variant="text">
            Fechar
          </AlphaModal.Action>
          <AlphaModal.Action
            onClick={handleSavePassword}
            color="primary"
            variant="contained"
            sx={{ ml: 1 }}
            disabled={isLoading}
          >
            Salvar
          </AlphaModal.Action>
        </AlphaModal.Footer>
      </AlphaModal.Root>
    </AlphaBox>
  );
};

export default ProfileSecurity;

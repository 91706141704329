import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import { alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

interface ActionButtonProps extends ButtonProps {
  buttonType?: 'search' | 'clear';
}

type StyledButtonProps = Omit<ActionButtonProps, 'buttonType'> & {
  $buttonType?: 'search' | 'clear';
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== '$buttonType',
})<StyledButtonProps>(({ theme, $buttonType }) => ({
  borderRadius: '12px',
  padding: '8px 24px',
  fontSize: '0.875rem',
  fontWeight: 600,
  textTransform: 'none',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'hidden',
  minWidth: '120px',
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',

  ...($buttonType === 'search' && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      transform: 'translateY(-2px)',
    },
  }),

  ...($buttonType === 'clear' && {
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.grey[500], 0.1)
        : alpha(theme.palette.grey[600], 0.2),
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? alpha(theme.palette.grey[500], 0.2)
          : alpha(theme.palette.grey[600], 0.3),
      transform: 'translateY(-2px)',
    },
  }),

  '&:active': {
    transform: 'translateY(0)',
    boxShadow: 'none',
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(120deg, transparent, rgba(255,255,255,0.2), transparent)',
    transform: 'translateX(-100%)',
  },

  '&:hover::after': {
    transform: 'translateX(100%)',
    transition: 'transform 0.6s',
  },
}));

const ActionButton: React.FC<ActionButtonProps> = ({
  buttonType = 'search',
  children,
  ...props
}) => {
  return (
    <StyledButton
      $buttonType={buttonType}
      startIcon={buttonType === 'search' && <SearchIcon sx={{ mr: -1 }} />}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default ActionButton;
